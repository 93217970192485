import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import validator from 'email-validator'
import { Grid, CircularProgress } from '@material-ui/core'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const CTAContainer = styled.div`
  margin: auto;
  max-width: 1200px;
  padding: 150px 50px;

  text-align: center;

  .sign-up {
    position: relative;
    margin: 75px 0 0 0;

    input,
    button {
      margin: 0;
      padding: 10px 15px;

      border: none;
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
    }

    input {
      width: 30%;

      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    button {
      background: #3e9ed6;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      color: #ffffff;

      &:active {
        background: #388fc2;
      }
    }

    .loader {
      position: absolute;
      left: calc(50% - 15px);

      color: #3e9ed6;
    }

    .error {
      border: 1px solid #ff0000;
      border-right: none;
      color: #ff0000;
    }
  }

  p.error-message {
    padding: 10px;
    color: #ff0000;
  }

  @media only screen and (max-width: 600px) {
    padding: 50px;

    h1 {
      font-size: 2.25rem;
    }

    .sign-up {
      margin: 50px 0 0 0;

      input {
        width: 100%;
        margin: 0 0 10px 0;

        border-radius: 10px;
      }

      button {
        border-radius: 10px;
      }

      .error {
        border: 1px solid #ff0000;
      }
    }
  }
`

const DetailsContainer = styled.div`
  margin: 0 0 50px 0;
  padding: 50px;

  background: #ffffff;
  color: #21242b;

  .details-content {
    margin: auto;
    max-width: 1200px;
    padding: 0 50px;

    div:first-child {
      display: flex;
      align-items: center;
      padding: 0 15px 0 0;
    }

    div:nth-child(2) {
      padding: 0 0 0 15px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 50px 0;

    .details-content {
      div:first-child,
      div:nth-child(2) {
        margin: 0 0 25px 0;
        padding: 0;
      }
    }
  }
`

class RootPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      isLoading: false,
      success: false,
      error: false,
    }

    this.signUpClick = this.signUpClick.bind(this)
    this.inputKeyPress = this.inputKeyPress.bind(this)
  }

  signUpClick() {
    if (!validator.validate(this.state.email)) {
      this.setState({ error: true })

      return
    }

    this.setState({ isLoading: true, error: false })

    addToMailchimp(this.state.email).then(result => {
      setTimeout(() => this.setState({ isLoading: false, success: true }), 1000)
    })
  }

  inputKeyPress(key) {
    if (key === 'Enter') {
      this.signUpClick()
    }
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Paintball Field Bookings Software"
          keywords={['paintball', 'field', 'bookings', 'software']}
        />
        <CTAContainer>
          <h1>Paintball Bookings Made Simple</h1>
          <p>Coming 2020</p>
          <div className="sign-up">
            {this.state.isLoading && <CircularProgress className="loader" />}
            {!this.state.success && (
              <>
                <input
                  className={this.state.error ? 'error' : undefined}
                  disabled={this.state.isLoading}
                  type="text"
                  placeholder="Enter Email"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  onKeyPress={e => this.inputKeyPress(e.key)}
                />
                <button
                  disabled={this.state.isLoading}
                  onClick={this.signUpClick}
                >
                  Keep Me Updated
                </button>
              </>
            )}
            {this.state.success && <h3>Thanks for subscribing!</h3>}
          </div>
          {this.state.error && (
            <p className="error-message">Please enter a valid email address.</p>
          )}
        </CTAContainer>
        <DetailsContainer>
          <Grid container className="details-content">
            <Grid item xs={12} sm={6}>
              <h2>
                An experience your customers will love, built specifically for
                paintball.
              </h2>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p>
                With our platform, you'll be able to manage your bookings,
                packages, and everything else your field requires to operate.
                Fine tuned control, available on everything from a mobile device
                to a desktop computer.
              </p>
              <br />
              <p>
                We'll handle the payments, waivers, and onboarding while you
                focus on what really matters - running your business!
              </p>
            </Grid>
          </Grid>
        </DetailsContainer>
      </Layout>
    )
  }
}

export default RootPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
